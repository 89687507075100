import _ from 'lodash'
import TOML from '@iarna/toml'

export const utParseData = function (toml) {
  const dbJson = TOML.parse(toml)
  _.each(dbJson.functions, fun => {
    fun.controls = []
    _.each(dbJson.controls, control => {
      if (control.functions) {
        if (control.functions.find(element => element === fun.id)) {
          fun.controls.push(control)
        }
      }
    })
  })
  _.each(dbJson.cycles, cycle => {
    cycle.controls = []
    _.each(dbJson.controls, control => {
      if (control.cycles) {
        if (control.cycles.find(element => element === cycle.id)) {
          cycle.controls.push(control)
        }
      }
    })
  })
  const response = {
    toml: toml,
    dbJson: dbJson
  }
  return response
}
